import { createThread } from '@wix/ambassador-forms-assistant-v1-thread/http';
import { createThread as createThreadV2 } from '@wix/ambassador-forms-assistant-v2-thread/http';
import {
  queryMessages,
  createMessage,
} from '@wix/ambassador-forms-assistant-v1-message/http';
import {
  queryMessages as queryMessagesV2,
  createMessage as createMessageV2,
} from '@wix/ambassador-forms-assistant-v2-message/http';
import { FormValues } from '@wix/form-viewer';
import { Thread } from '@wix/ambassador-forms-assistant-v1-thread/types';
import {
  Message,
  Role,
} from '@wix/ambassador-forms-assistant-v1-message/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export interface AIAssistantState {
  formId?: string;
  thread?: Thread;
  messages: Message[];
  formData?: Record<string, any>;
  assistantTyping: boolean;
  apiErrorOcurred?: boolean;
}

interface CreateAIAssistantServiceProps {
  initialState?: AIAssistantState;
  httpClient: IHttpClient;
  useV2Api: boolean;
  updateFormAssistantStateProp: (state: AIAssistantState) => void;
}

export interface AiAssistantUserInput {
  message: string;
  formValues: FormValues;
}

export interface AIAssistantService {
  submitUserMessage: (userInput: AiAssistantUserInput) => void;
  setFormId: (formId: string) => void;
  initThread: (formData: FormValues) => void;
}

export function createAIAssistantService(
  props: CreateAIAssistantServiceProps,
): AIAssistantService {
  const { initialState, httpClient, useV2Api } = props || {};
  let state: AIAssistantState = initialState || {
    assistantTyping: false,
    messages: [],
  };

  const queryMessagesRequestBuilder = useV2Api
    ? queryMessagesV2
    : queryMessages;
  const createMessagesRequestBuilder = useV2Api
    ? createMessageV2
    : createMessage;
  const createThreadRequestBuilder = useV2Api ? createThreadV2 : createThread;

  const updateFormAssistantState = (newState: Partial<AIAssistantState>) => {
    const updatedSortedMessages = newState.messages?.sort((m1, m2) => {
      if (!m2.createdDate) {
        return 1;
      }
      return (
        new Date(m2.createdDate).getTime() - new Date(m1.createdDate).getTime()
      );
    });
    state = {
      ...state,
      ...newState,
      ...(updatedSortedMessages ? { messages: updatedSortedMessages } : {}),
    };
    props.updateFormAssistantStateProp(state);
  };

  const initThread = async (formData: FormValues) => {
    if (state.thread) {
      return;
    }
    updateFormAssistantState({
      assistantTyping: true,
      apiErrorOcurred: false,
    });
    try {
      const createThreadResponse = await httpClient.request(
        createThreadRequestBuilder({
          thread: { formId: state.formId },
          formData,
        }),
      );

      if (createThreadResponse.data?.thread?.id) {
        const threadId = createThreadResponse.data.thread.id;
        const messagesResponse = await httpClient.request(
          queryMessagesRequestBuilder({ threadId }),
        );

        updateFormAssistantState({
          thread: createThreadResponse.data?.thread,
          assistantTyping: false,
          messages: messagesResponse.data?.messages,
        });
      }
    } catch (e) {
      console.error(e);
      updateFormAssistantState({
        assistantTyping: false,
        apiErrorOcurred: true,
      });
    }
  };

  const setFormId = (formId: string) => {
    updateFormAssistantState({ formId });
  };

  const reloadMessages = async () => {
    const messagesResponse = await httpClient.request(
      queryMessagesRequestBuilder({ threadId: state?.thread?.id }),
    );
    updateFormAssistantState({
      assistantTyping: false,
      messages: messagesResponse.data?.messages,
      formData: messagesResponse.data?.formData || undefined,
    });
  };

  const submitUserMessage = async (
    userInput: AiAssistantUserInput,
  ): Promise<void> => {
    if (state.apiErrorOcurred) {
      await reloadMessages();
    }

    updateFormAssistantState({
      apiErrorOcurred: false,
      assistantTyping: true,
      messages: [
        {
          content: userInput.message,
          role: Role.USER,
          id: 'userMessage-temp',
        },
        ...state.messages,
      ],
    });

    try {
      await httpClient.request(
        createMessagesRequestBuilder({
          threadId: state.thread?.id,
          message: { content: userInput.message },
          formData: userInput.formValues,
        }),
      );
      await reloadMessages();
    } catch (e) {
      console.error(e);
      updateFormAssistantState({
        assistantTyping: false,
        apiErrorOcurred: true,
      });
    }
  };

  return {
    submitUserMessage,
    setFormId,
    initThread,
  };
}
